// import Keycloak from 'keycloak-js';
 
// const keycloak = new Keycloak({
//   url: 'http://152.117.94.52:8180',
//   realm: 'dapto',
//   clientId: 'react-client',
// });
 
// export default keycloak;

import Keycloak from 'keycloak-js';
const keycloakBaseUrl= process.env.REACT_APP_KEYCLOAK_API_BASE_URL;

const keycloak = new Keycloak({
  url: `${keycloakBaseUrl}`,
  realm: 'dapto',
  clientId: 'react-client',
});

export default keycloak;