import keycloakInstance from "./keycloak";
const keycloakBaseUrl= process.env.REACT_APP_KEYCLOAK_API_BASE_URL;

const getUpdatedToken = async (): Promise<string> => {
  const keycloak = keycloakInstance;

  if (!keycloak.authenticated) {
    console.warn("User is not authenticated.");
    return "";
  }

  try {
    await keycloak.updateToken(30);
    localStorage.setItem("keycloakToken", keycloak.token || "");
    return keycloak.token || "";
  } catch (error) {
    console.error("Token refresh failed. Logging out user.", error);
    keycloak.logout();
    return "";
  }
};

// Monkey patch global fetch
const originalFetch = window.fetch;

window.fetch = async (url: RequestInfo | URL, options: RequestInit = {}) => {
  let token = localStorage.getItem("keycloakToken");
  let refreshToken = localStorage.getItem("keycloakRefreshToken") || "";

  if (!token) {
    token = await getUpdatedToken();
    refreshToken = localStorage.getItem("keycloakRefreshToken") || "";
  }

  // ✅ Ensure `url` is always a string
  let urlString = "";
  if (typeof url === "string") {
    urlString = url;
  } else if (url instanceof URL) {
    urlString = url.toString();
  } else if (url instanceof Request) {
    urlString = url.url;
  } else {
    throw new Error("Invalid URL type provided to fetch");
  }

  const isKeycloakAdminAPI = urlString.includes(`${keycloakBaseUrl}/admin`);

  const headers: Record<string, string> = {
    "Authorization": `Bearer ${token}`,
  };

  // ✅ Only include `X-Refresh-Token` if NOT calling Keycloak Admin API
  if (!isKeycloakAdminAPI && refreshToken) {
    headers["X-Refresh-Token"] = refreshToken;
  }

  // ✅ Only add "Content-Type" if body is not `FormData`
  if (options.body && !(options.body instanceof FormData)) {
    headers["Content-Type"] = "application/json";
  }

  const modifiedOptions: RequestInit = {
    ...options,
    headers,
  };

  console.log("🔹 Sending request to:", urlString);
  console.log("🔹 Headers:", modifiedOptions.headers);

  let response = await originalFetch(urlString, modifiedOptions);

  if (response.status === 401) {
    console.warn("Unauthorized request. Attempting token refresh...");
    token = await getUpdatedToken();
    refreshToken = localStorage.getItem("keycloakRefreshToken") || "";

    if (!token) throw new Error("Failed to refresh token");

    headers["Authorization"] = `Bearer ${token}`;

    if (!isKeycloakAdminAPI && refreshToken) {
      headers["X-Refresh-Token"] = refreshToken;
    }

    console.log("🔹 Retrying request with new token:", token);
    response = await originalFetch(urlString, modifiedOptions);
  }

  return response;
};
