//Code for implemeting Azure authentication

// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./i18n";
// import App from "./App";
// import { UserProvider } from './userContext';

// import { Provider } from "react-redux";
// import { configureStore } from "./redux/store";
// import { BrowserRouter } from "react-router-dom";
// import { PublicClientApplication } from "@azure/msal-browser";
// import { MsalProvider } from "@azure/msal-react";
// import { msalConfig } from "./msalConfig"; // Ensure this file is correctly configured

// const msalInstance = new PublicClientApplication(msalConfig);

// const initializeMsal = async () => {
//   await msalInstance.initialize();
//   ReactDOM.createRoot(document.getElementById("root")!).render(
//     <Provider store={configureStore({})}>
//       <MsalProvider instance={msalInstance}>

//         <BrowserRouter basename={process.env.PUBLIC_URL}>
//           <UserProvider>
//             <App />
//           </UserProvider>
//         </BrowserRouter>

//       </MsalProvider>
//     </Provider>
//   );
// };

// initializeMsal().catch(console.error);

//Code for implemetning Keycloak authentication
import './fetchInterceptor'; // This is for refreshing the keycloak token before each API call
import React from 'react';
import "./i18n";
import ReactDOM from "react-dom/client";
import App from './App';
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { KeycloakProvider } from './keycloakContext';

import { BrowserRouter } from 'react-router-dom';

ReactDOM.createRoot(document.getElementById("root")!).render(
  
    <Provider store={configureStore({})}>
      <KeycloakProvider>
       
          <BrowserRouter>
            <App />
          </BrowserRouter>
       
      </KeycloakProvider>
    </Provider>
  
);