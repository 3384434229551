// import React, { createContext, useContext, useEffect, ReactNode, useState } from 'react';
// import Keycloak from 'keycloak-js'; // Use KeycloakInstance type
// import keycloakInstance from './keycloak'; // Ensure this exports your Keycloak instance
// import { Spinner } from 'react-bootstrap';

// interface KeycloakContextType {
//   keycloak: Keycloak;
//   initialized: boolean;
//   isAuthenticated: boolean;
//   username: string | null;
//   name: string | null;
//   profilePhotoUrl: string | null;
// }

// const KeycloakContext = createContext<KeycloakContextType | undefined>(undefined);

// export const useKeycloak = (): KeycloakContextType => {
//   const context = useContext(KeycloakContext);
//   if (!context) {
//     throw new Error('useKeycloak must be used within a KeycloakProvider');
//   }
//   return context;

// };

// export const KeycloakProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
//   const [keycloakInstanceState, setKeycloakInstance] = useState<Keycloak>(keycloakInstance);
//   const [initialized, setInitialized] = useState<boolean>(false);
//   const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
//   const [username, setUsername] = useState<string | null>(null);
//   const [name, setName] = useState<string | null>(null);
//   const [profilePhotoUrl, setProfilePhotoUrl] = useState<string | null>(null);

//   useEffect(() => {
//     const initKeycloak = async () => {
//       try {
//         const currentUrl = window.location.href;
//         const authenticated = await keycloakInstanceState.init({
//           onLoad: 'check-sso', // or 'login-required' depending on your flow
//           silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
//           redirectUri: currentUrl,
//         });

//         if (authenticated) {
//           const userProfile = await keycloakInstanceState.loadUserProfile();
//           setUsername(userProfile.username ?? null); 
//           const fullName = `${userProfile.firstName ?? ''} ${userProfile.lastName ?? ''}`.trim();
//           setName(fullName.length > 0 ? fullName : null);
//           // setProfilePhotoUrl(userProfile.photoUrl ?? null); 
//         }

//         setIsAuthenticated(authenticated);
//         setInitialized(true);
//       } catch (error) {
//         console.error('Failed to initialize Keycloak:', error);
//       }
//     };

//     initKeycloak();
//   }, [keycloakInstanceState]);

//   return (
//     <KeycloakContext.Provider value={{ keycloak: keycloakInstanceState, initialized, isAuthenticated, username, name, profilePhotoUrl }}>
//       {initialized ? children :  <div className="d-flex justify-content-center align-items-center vh-100">
//         <Spinner animation="grow" variant="primary" className="display-1" />
//       </div>}
//     </KeycloakContext.Provider>
//   );
// };

import React, { createContext, useContext, useEffect, ReactNode, useState } from 'react';
import Keycloak from 'keycloak-js';
import keycloakInstance from './keycloak'; // Ensure this exports your Keycloak instance
import { Spinner } from 'react-bootstrap';
import logo from './assets/images/logo-dapto2-modified.png';

interface KeycloakContextType {
  keycloak: Keycloak;
  initialized: boolean;
  isAuthenticated: boolean;
  username: string | null;
  name: string | null;
  profilePhotoUrl: string | null;
  tenantId: string | null;
  userId: string | null;
  email: string | null;
  roles: string[] | null;
}

const KeycloakContext = createContext<KeycloakContextType | undefined>(undefined);

export const useKeycloak = (): KeycloakContextType => {
  const context = useContext(KeycloakContext);
  if (!context) {
    throw new Error('useKeycloak must be used within a KeycloakProvider');
  }
  return context;
};

export const KeycloakProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [keycloakInstanceState, setKeycloakInstance] = useState<Keycloak>(keycloakInstance);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [username, setUsername] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState<string | null>(null);
  const [tenantId, setTenantId] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [roles, setRoles] = useState<string[] | null>(null);

  useEffect(() => {
    const initKeycloak = async () => {
      try {
        const authenticated = await keycloakInstanceState.init({
          onLoad: 'login-required', // This ensures the login process is initiated if not authenticated
          pkceMethod: 'S256',
          checkLoginIframe: false,
        });

        if (authenticated) {
          console.log('Authenticated successfully');
          // Store the token in localStorage
          localStorage.setItem('keycloakToken', keycloakInstanceState.token || '');
          localStorage.setItem('keycloakRefreshToken', keycloakInstanceState.refreshToken || '');
          const userProfile = await keycloakInstanceState.loadUserProfile();
          //console.log("🔹 User Profile from Keycloak:", userProfile);
          setUsername(userProfile.username ?? null);
          setEmail(userProfile.email ?? null);
          setUserId(userProfile.id ?? null); // Default Keycloak user ID
          const fullName = `${userProfile.firstName ?? ''} ${userProfile.lastName ?? ''}`.trim();
          setName(fullName.length > 0 ? fullName : null);
          const extractedTenantId = userProfile.attributes?.tenant_id 
  ? Array.isArray(userProfile.attributes.tenant_id) 
    ? userProfile.attributes.tenant_id[0] 
    : null 
  : null;


          setTenantId(extractedTenantId);
          // ✅ Fetch Roles from Token
          const decodedToken: any = keycloakInstanceState.tokenParsed;
          setRoles(decodedToken.realm_access?.roles || []);
          // ✅ Store in localStorage for persistence
          localStorage.setItem("user_id", userProfile.username ?? "");
          localStorage.setItem("tenant_id", extractedTenantId ?? "");

          
        }

        setIsAuthenticated(authenticated);
        setInitialized(true);

        // Handle token expiration
        keycloakInstanceState.onTokenExpired = async () => {
          try {
            await keycloakInstanceState.updateToken(30); // Refresh token if it's about to expire
            localStorage.setItem('keycloakToken', keycloakInstanceState.token || ''); // Update token in localStorage
          } catch (error) {
            console.error("Token refresh failed", error);
            keycloakInstanceState.logout(); // Log out if refresh fails
          }
        };

      } catch (error) {
        console.error('Failed to initialize Keycloak:', error);
        setInitialized(true); // Still set initialized to true to avoid endless loading
      }
    };

    if (!initialized) {
      initKeycloak();
    }
  }, [initialized, keycloakInstanceState]);

  return (
    <KeycloakContext.Provider value={{ keycloak: keycloakInstanceState, initialized, isAuthenticated, username, name, profilePhotoUrl, tenantId, userId, email,  roles }}>
      {initialized ? children : (
        <div className="position-relative  d-flex justify-content-center align-items-center vh-100">
          <img
            src={logo} 
            alt="Loading"
            className="align-items-center  top-0 start-0 w-20 h-50"
             
          />
          <div className="position-absolute w-100 d-flex justify-content-center" style={{ bottom: '100px' }}>
            <Spinner animation="grow" variant="primary" className="display-1" />
          </div>
        </div>
      )}
    </KeycloakContext.Provider>
  );
};
